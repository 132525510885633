import React, { useEffect, useMemo, useState } from "react";
import api from "../utils/api.utils";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import ImeiReader from "../components/ImeiReader";
import { ViewLancamento } from "../components/ViewLancamento";
import PrintTransaction from "../components/PrintTransaction";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../context/AuthContext";

const MeuCaixa = ({
  message,
  setMessage,
  error,
  setError,
  formatarDataEHora,
  formatarData,
}) => {
  const navigate = useNavigate();

  const { userData } = useAuth();

  const { userId } = userData.userData || "";

  const [loading, setLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(getCurrentFormattedDate());

  const [caixaDiario, setCaixaDiario] = useState(null);

  const [currentTransaction, setCurrentTransaction] = useState("");

  const [showModalDev, setShowModalDev] = useState(false);
  const [showModalTransaction, setShowModalTransaction] = useState(false);

  const formaPagamentoMap = {
    pix: "Pix",
    dinheiro: "Dinheiro",
    cartao_debito: "Débito",
    cartao_credito: "Crédito",
    transferencia_bancaria: "Transferência bancária",
    saldo_carteira: "Saldo da Carteira",
  };

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return valorFormatado;
    }
  };

  //ABRE O CAIXA
  const handleAbrirCaixa = async () => {
    try {
      const abrirCaixa = await api.abrirCaixa({ userId, selectedDate });
      setCaixaDiario(abrirCaixa);
      navigate(0);
    } catch (error) {
      console.log(error);
      if (error === "Sua sessão expirou, é necessário fazer login novamente.") {
        navigate("/login/");
      } else {
        setError(error);
      }
    }
  };

  //DEVOLVER
  const handleDevolver = async (vendaID) => {
    try {
      setLoading(true);
      const devolvido = await api.devolverVenda({ vendaID, userData });
      setLoading(false);
      if (devolvido) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sumTransactions = () => {
    return transactions
      .reduce((total, caixa) => {
        if (caixa.saldo_carteira === false && caixa.type === "DEVOLUCAO") {
          return total - caixa.valorTotal;
        } else if (caixa.saldo_carteira === false && caixa.type === "VENDAS") {
          return total + caixa.valorTotal;
        } else {
          return total;
        }
      }, 0)
      .toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  };

  const valorTotal = useMemo(() => sumTransactions(), [transactions]);

  const handleView = (Transaction) => {
    setCurrentTransaction(Transaction);
    setShowModalTransaction(true);
  };
  const cancelDelete = () => {
    setShowModalTransaction(false);
    setCurrentTransaction(null);
  };
  const imprimirVenda = (currentTransaction, folha) => {
    let tamanhoFolha = folha;
    // Abrir nova página com target="_blank"
    const novaPagina = window.open("", "_blank");

    // Adicionar o conteúdo que você deseja imprimir na nova página
    novaPagina.document.write(
      "<html><head><title>Detalhes da Venda</title></head><body>"
    );

    // Adicionar informações específicas
    novaPagina.document.write("<h1>Detalhes da Venda</h1>");
    ReactDOM.render(
      <PrintTransaction
        transaction={currentTransaction}
        formatarDataEHora={formatarDataEHora}
        formatarValor={formatarValor}
        tamanhoFolha={tamanhoFolha}
      />,
      novaPagina.document.body
    );

    // Fechar o corpo da página
    novaPagina.document.write("</body></html>");
    novaPagina.document.close();

    // Iniciar a caixa de diálogo de impressão
    novaPagina.print();
  };

  //VERIFICA SE EXISTE CAIXA ABERTO
  useEffect(() => {
    const checkCaixaAberto = async () => {
      try {
        setLoading(true);
        const caixaAberto = await api.checkCaixaAberto(selectedDate);
        if (!caixaAberto.msg) {
          setCaixaDiario(caixaAberto);
          setTransactions(caixaAberto.transactions);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    checkCaixaAberto();
  }, [selectedDate]);

  const renderTable = () => {
    return (
      <table className="table mb-0 table-striped table-hover">
        <thead>
          <tr>
            <th>Data e hora</th>
            <th>Venda</th>
            <th>Cliente</th>
            <th>Forma de pagamento</th>
            <th>Usou carteira?</th>
            <th>Valor</th>
            <th>Vendedor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactions
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((caixa, index) => {
              return (
                <tr key={index}>
                  <td>{formatarDataEHora(caixa.createdAt)}h</td>
                  <td>
                    {caixa && (
                      <b>
                        {caixa.type.slice(0, 3)}
                        {caixa.number}
                      </b>
                    )}
                  </td>
                  <td>{caixa.parteId && caixa.parteId.fullName}</td>
                  <td>
                    {formaPagamentoMap[caixa.formaPagamento]}{" "}
                    {caixa.status === "DEVOLVIDO" && (
                      <>
                        <div className="btn btn-warning disabled">
                          Devolvido
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {caixa.saldo_carteira === true ? (
                      <span>Sim</span>
                    ) : (
                      <span>Não</span>
                    )}
                  </td>
                  <td
                    style={{
                      color:
                        caixa.saldo_carteira === false &&
                        caixa.type === "DEVOLUCAO"
                          ? "red"
                          : caixa.saldo_carteira === false &&
                            caixa.type === "VENDAS"
                          ? "green"
                          : "black",
                      fontWeight: "500",
                    }}
                  >
                    {caixa.saldo_carteira === true &&
                    caixa.type === "VENDAS" ? (
                      <span>0</span>
                    ) : (
                      <>
                        {caixa.saldo_carteira === true &&
                        caixa.type === "DEVOLUCAO" ? (
                          <>0</>
                        ) : (
                          <>R$ {formatarValor(caixa.valorTotal)}</>
                        )}
                      </>
                    )}
                  </td>
                  {/* <td>R$ {formatarValor(caixa.valorTotal)}</td> */}
                  <td>
                    {caixa &&
                      caixa.transactionUser &&
                      caixa.transactionUser.fullName}
                  </td>
                  <td
                    style={{
                      color:
                        caixa.saldo_carteira === false &&
                        caixa.type === "DEVOLUCAO"
                          ? "red"
                          : caixa.saldo_carteira === false &&
                            caixa.type === "VENDAS"
                          ? "green"
                          : "black",
                      fontWeight: "300",
                    }}
                  >
                    <div
                      className="btn btn-success"
                      onClick={() => handleView(caixa)}
                      title="Ver"
                    >
                      <i className="bi bi-eye-fill"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const [imeiArray, setImeiArray] = useState([]);
  const [motivo, setMotivo] = useState("");

  //ADD IMEI TO DEVOLUCAO
  const handleImeiAdd = async (imei, serial) => {
    try {
      const imeiFound = await api.buscarImeiDadosDev(imei);

      const isImeiAlreadyAdded = imeiArray.some(
        (existingImei) => existingImei.number === imei
      );
      if (!isImeiAlreadyAdded) {
        let valorVenda;
        let valorCompra;
        let lastTransaction;

        if (imeiFound.transactions && imeiFound.transactions.length > 0) {
          lastTransaction =
            imeiFound.transactions[imeiFound.transactions.length - 1];

          const valorVendaDev = lastTransaction.produtos.find(
            (produto) => imeiFound._id === produto.imeiId
          ).valorVenda;

          valorCompra = lastTransaction.produtos.find(
            (produto) => imeiFound._id === produto.imeiId
          ).valorCompra;

          valorVenda = valorVendaDev;
        } else {
          valorVenda = 0;
        }

        setImeiArray([
          ...imeiArray,
          {
            number: imei,
            serial: serial,
            valorCompra: valorCompra,
            valorVenda: valorVenda,
            produtoId: imeiFound.produtoId,
            imeiId: imeiFound._id,
            transactionId: lastTransaction._id,
            parteId: lastTransaction.parteId,
          },
        ]);
      } else {
        setError("IMEI não encontrado!");
      }
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  //remove Imei
  const removeImei = (index) => {
    const updatedImeiArray = [...imeiArray];
    updatedImeiArray.splice(index, 1);
    setImeiArray(updatedImeiArray);
  };

  const closeModalDev = () => {
    setShowModalDev(false);
    setImeiArray([]);
  };

  const [valorTotalDev, setValorTotalDev] = useState(0);
  const sumImeis = () => {
    return imeiArray
      .reduce((total, imei) => {
        return total + (imei ? imei.valorVenda : 0);
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    const totalValue = sumImeis();
    setValorTotalDev(parseFloat(totalValue));
  }, [imeiArray]);

  const handleSubmitDev = async (e) => {
    e.preventDefault();
    const { _id } = caixaDiario;
    const caixa = _id;
    try {
      setLoading(true);
      await api.devolverImei({ imeiArray, motivo, caixa, valorTotalDev });
      setImeiArray([]);
      setMotivo(null);
      setShowModalDev(false);
      setMessage("Imei(s) devolvido(s) com sucesso!");
      navigate(0);
      setTimeout(() => {
        setMessage("");
      }, 4000);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  return (
    <div
      className="p-3 sys-back d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-cash-coin"></i> Caixa aberto
        </h3>
        {!caixaDiario && (
          <div className="d-flex flex-column align-items-center">
            <h5>Não existe nenhum caixa aberto para o dia de hoje.</h5>
            <div className="btn btn-outline-success" onClick={handleAbrirCaixa}>
              Abrir caixa
            </div>
          </div>
        )}
        <div className="mb-3">
          <input
            className="form-control"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            disabled
            hidden
          />
          <div className="d-flex align-items-center alert alert-info">
            <span>
              Valor Atual:{" "}
              <b>R$ {formatarValor(valorTotal) || <span>0,00</span>}</b>
            </span>
          </div>
        </div>
      </div>
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}

      {!loading ? (
        transactions.length > 0 ? (
          <div className="d-flex align-items-start">
            <div className="border p-2  rounded w-100 d-flex">
              {!loading ? renderTable() : <CircularProgress />}
            </div>
            <ViewLancamento
              setShowModalView={setShowModalTransaction}
              showModalView={showModalTransaction}
              currentLancamento={currentTransaction}
              setCurrentLancamento={setCurrentTransaction}
              formatarValor={formatarValor}
              formatarDataEHora={formatarDataEHora}
              formatarData={formatarData}
              cancelView={cancelDelete}
              handleDevolver={handleDevolver}
              imprimirVenda={imprimirVenda}
            />
          </div>
        ) : (
          <div className="text-center text-dark alert alert-warning mt-3">
            Nada registrado!
          </div>
        )
      ) : (
        <CircularProgress />
      )}
      <div
        className={`modal modal-lg ${showModalDev ? "d-flex" : "d-none"}`}
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="modal-dialog ">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5>Devolução de IMEIs</h5>
            </div>
            <div className="modal-body">
              <ImeiReader onImeiAdd={handleImeiAdd} />
              <div>
                <label>IMEIs adicionados:</label>
                <ul className="p-0">
                  {imeiArray.map((imei, index) => (
                    <li className="d-flex align-items-center" key={index}>
                      <div
                        className="btn btn-danger"
                        style={{ width: "auto" }}
                        onClick={() => removeImei(index)}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                      <div className="lista-imeis w-100">
                        <b> IMEI {imeiArray.length - index}:</b> {imei.number}{" "}
                        {imei.serial ? <> - (Serial: {imei.serial})</> : null}|
                        R$ {formatarValor(imei.valorVenda)}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <label>Motivo da Devolução:</label>
                <textarea
                  rows={3}
                  className="form-control"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                />
              </div>
            </div>
            {error && <div className="alert alert-warning m-2">{error}</div>}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmitDev}
              >
                Realizar devolução
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModalDev}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeuCaixa;
