import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { Link, useNavigate } from "react-router-dom";
import {
  CenterFocusStrong,
  Edit,
  MobileOff,
  SystemSecurityUpdateGood
} from "@mui/icons-material";
import { CloseButton } from "react-bootstrap";
import { CircularProgress } from "@mui/material";

const Produtos = ({
  message,
  setMessage,
  error,
  setError,
  loading,
  setLoading,
  userData
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingProdutoID, setDeletingProdutoID] = useState(null);

  const [loadingEdit, setLoadingEdit] = useState(false);

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingProduto, setEditingProduto] = useState({});

  const navigate = useNavigate();

  const [produtos, setProdutos] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [todosProdutos, setTodosProdutos] = useState([]);

  const [currentImeis, setCurrentImeis] = useState([]);
  const [currentProduto, setCurrentProduto] = useState("");
  const [isImeiModalOpen, setIsImeiModalOpen] = useState(false);

  const getImeis = async (produto) => {
    try {
      const imeisData = await api.getImeisByProdutoId(produto._id);
      setIsImeiModalOpen(true);
      setCurrentImeis(imeisData);
      setCurrentProduto(produto);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getProdutosImei = async () => {
      setLoading(true);
      const imeisData = await api.getEstoque();
      const agrupado = imeisData.reduce((acc, imei) => {
        const produtoId = imei.produtoId._id;
        if (!acc[produtoId]) {
          acc[produtoId] = {
            _id: produtoId,
            marca: imei.produtoId.marca,
            descricao: imei.produtoId.descricao,
            count: 0
          };
        }
        acc[produtoId].count++;
        // console.log(acc);
        return acc;
      }, {});
      setFilteredProdutos(Object.values(agrupado));
      setLoading(false);
    };
    getProdutosImei();
  }, []);

  useEffect(() => {
    const getProdutosSemImei = async () => {
      try {
        const produtos = await api.getProdutos();
        setProdutos(produtos);
      } catch (error) {
        console.error(error);
      }
    };

    getProdutosSemImei();
  }, []);

  useEffect(() => {
    setTodosProdutos([...filteredProdutos, ...produtos]);
  }, [filteredProdutos, produtos]);

  // Função para abrir o modal de edição
  const handleEdit = async (produtoID) => {
    try {
      // Buscar os detalhes do produto com base no produtoID
      setEditModalOpen(true);
      setLoadingEdit(true);
      const produto = await api.getProdutoByID(produtoID);
      setEditingProduto(produto);
      setLoadingEdit(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível carregar os detalhes do produto!");
    }
  };
  // Função para confirmar a edição
  const confirmEdit = async () => {
    try {
      setLoading(true);
      await api.editProduto(editingProduto._id, {
        descricao: editingProduto.descricao,
        marca: editingProduto.marca,
        qtd: editingProduto.qtd,
        valorCompraDb: editingProduto.valorCompraDb,
        valorVendaDb: editingProduto.valorVendaDb
      });
      setLoading(false);
      setEditModalOpen(false);
      // Recarregue os produtos após a edição
      const updatedProdutos = await api.getProdutos();
      setProdutos(updatedProdutos);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível editar o produto!");
    }
  };

  // Função para cancelar a edição
  const cancelEdit = () => {
    setEditModalOpen(false);
    setEditingProduto({});
  };
  const handleDelete = async (produtoID) => {
    setDeletingProdutoID(produtoID);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      const deletedProduto = await api.deleteProduto(deletingProdutoID);
      if (deletedProduto) {
        setLoading(false);
        setDeleteModalOpen(false);
        navigate(0);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível deletar o produto!");
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingProdutoID(null);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEstoque, setFilteredEstoque] = useState([]);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Filtrar o estoque com base no termo de pesquisa
    const filteredResults = todosProdutos.filter((produto) =>
      produto.descricao.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredEstoque(filteredResults);
  };

  const renderTable = () => {
    let produtosToRender = searchTerm ? filteredEstoque : todosProdutos;
    produtosToRender.sort((a, b) => b.count - a.count);
    if (loading === false) {
      if (produtosToRender.length > 0) {
        return (
          <table className="table mb-0 table-striped table-hover">
            <thead>
              <tr>
                <th>IMEI?</th>
                <th>Marca</th>
                <th>Descrição</th>
                <th>Quantidade</th>
                <th style={{ width: "15%" }}></th>
              </tr>
            </thead>
            <tbody>
              {produtosToRender.map((produto, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {produto.hasImei === false ? (
                        <MobileOff />
                      ) : (
                        <SystemSecurityUpdateGood />
                      )}
                    </td>
                    <td>{produto.marca}</td>
                    <td>{produto.descricao}</td>
                    <td>{produto.count || produto.qtd}</td>
                    <td style={{ textAlign: "right", fontSize: "0.8rem" }}>
                      <div className="d-flex">
                        <div
                          className="btn btn-info mx-2"
                          onClick={() => getImeis(produto)}
                        >
                          {" "}
                          <CenterFocusStrong style={{ fontSize: "1rem" }} />
                        </div>
                        {userData.admin && (
                          <div
                            className="btn btn-warning mx-2"
                            onClick={() => handleEdit(produto._id)}
                          >
                            <Edit style={{ fontSize: "1rem" }} />
                          </div>
                        )}
                        {produto.qtd === 0 &&
                        produto.hasImei !== "sim" &&
                        userData.admin ? (
                          <div
                            className="btn btn-danger"
                            onClick={() => handleDelete(produto._id)}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return (
          <div className="text-center text-dark alert alert-warning mt-3">
            Nenhum produto em estoque!
          </div>
        );
      }
    } else {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-phone-vibrate mx-3"></i>Produtos
        </h3>
        <div className="mb-3">
          <Link className="btn btn-success" to={"/produtos/cadastrando"}>
            Cadastrar Produto
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar produto"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      <div className="border p-2  shadow rounded w-100">{renderTable()}</div>

      {/* Modal de Confirmação */}
      <div
        className={`modal ${isDeleteModalOpen ? "d-flex" : "d-none"}`}
        style={{
          justifyContent: "center",
          alignItems: "center"
        }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmar Exclusão</h5>
            </div>
            <div className="modal-body">
              Tem certeza que deseja excluir este produto?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelDelete}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal de Edit */}
      <div
        className={`modal ${
          isEditModalOpen ? "d-flex justify-content-end" : "d-none"
        }`}
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "80vw"
        }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          style={{ minWidth: "950px" }}
          className="modal-dialog "
          role="document"
        >
          <div className="modal-content modal-style">
            <div className="modal-header">
              <h5 className="modal-title">Editando produto</h5>
            </div>
            <div className="modal-body">
              {!loadingEdit ? (
                <form>
                  <div className="mb-3">
                    <label htmlFor="editNome" className="form-label">
                      Nome:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="editNome"
                      value={editingProduto.descricao || ""}
                      onChange={(e) =>
                        setEditingProduto({
                          ...editingProduto,
                          descricao: e.target.value
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editMarca" className="form-label">
                      Marca:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="editMarca"
                      value={editingProduto.marca || ""}
                      onChange={(e) =>
                        setEditingProduto({
                          ...editingProduto,
                          marca: e.target.value
                        })
                      }
                    />
                  </div>
                  {editingProduto.hasImei === "nao" && (
                    <div>
                      <div className="mb-3">
                        <label htmlFor="editQuantidade" className="form-label">
                          Quantidade:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="editQuantidade"
                          value={editingProduto.qtd || 0}
                          onChange={(e) =>
                            setEditingProduto({
                              ...editingProduto,
                              qtd: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="editValorCompra" className="form-label">
                          Valor de Compra:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="editValorCompra"
                          value={editingProduto.valorCompraDb || 0}
                          onChange={(e) =>
                            setEditingProduto({
                              ...editingProduto,
                              valorCompraDb: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="editValorVenda" className="form-label">
                          Valor de Venda:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="editValorVenda"
                          value={editingProduto.valorVendaDb || 0}
                          onChange={(e) =>
                            setEditingProduto({
                              ...editingProduto,
                              valorVendaDb: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </form>
              ) : (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelEdit}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmEdit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal DE VIEW IMEIS */}
      <div
        className={`modal ${isImeiModalOpen ? "d-flex" : "d-none"}`}
        style={{
          justifyContent: "center"
          // alignItems: "center"
        }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg   shadow rounded" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{currentProduto.descricao}</h5>
              <CloseButton onClick={() => setIsImeiModalOpen(false)} />
            </div>
            <div className="modal-body">
              <p>Imeis disponíveis em estoque:</p>
              <ul>
                {currentImeis.map((imei, index) => {
                  return (
                    <li key={index}>
                      <small>{currentProduto.descricao}</small> -{" "}
                      <b>{imei.number}</b>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Produtos;
