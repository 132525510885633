import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { Link, useNavigate } from "react-router-dom";
import ModalDeleteImei from "../components/ModalDeleteImei";
import { CircularProgress } from "@mui/material";

const Dispositivos = ({ error, setError, loading, setLoading, userData }) => {
  const [estoque, setEstoque] = useState([]);

  const [message, setMessage] = useState("");

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCompra, setDeletingCompra] = useState(null);
  const [currentIMEI, setCurrentIMEI] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEstoque, setFilteredEstoque] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (searchTerm ? filteredEstoque : estoque).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const totalItems = searchTerm ? filteredEstoque.length : estoque.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxPagesToShow = 5; // Número máximo de páginas a mostrar ao mesmo tempo

    const createPageArray = (from, to) => {
      const result = [];
      for (let i = from; i <= to; i++) {
        result.push(i);
      }
      return result;
    };

    if (totalPages <= maxPagesToShow) {
      pageNumbers.push(...createPageArray(1, totalPages));
    } else {
      const leftPages = Math.max(
        1,
        currentPage - Math.floor(maxPagesToShow / 2)
      );
      const rightPages = Math.min(
        totalPages,
        currentPage + Math.floor(maxPagesToShow / 2)
      );

      if (leftPages > 1) pageNumbers.push(1);
      if (leftPages > 2) pageNumbers.push("...");
      pageNumbers.push(...createPageArray(leftPages, rightPages));
      if (rightPages < totalPages - 1) pageNumbers.push("...");
      if (rightPages < totalPages) pageNumbers.push(totalPages);
    }
    return (
      <nav>
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="page-item clickable">
              <Link
                to={""}
                onClick={() => paginate(currentPage - 1)}
                className="page-link"
              >
                &laquo;
              </Link>
            </li>
          )}
          {pageNumbers.map((number, index) =>
            number === "..." ? (
              <li key={index} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li
                key={index}
                className={`page-item clickable ${
                  number === currentPage ? "active" : ""
                }`}
              >
                <Link
                  to={""}
                  onClick={() => paginate(number)}
                  className="page-link"
                >
                  {number}
                </Link>
              </li>
            )
          )}
          {currentPage < totalPages && (
            <li className="page-item clickable">
              <Link
                to={""}
                onClick={() => paginate(currentPage + 1)}
                className="page-link"
              >
                &raquo;
              </Link>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    const getEstoque = async () => {
      try {
        setLoading(true);
        const estoque = await api.getEstoque();
        setEstoque(estoque);
        setLoading(false);
      } catch (error) {
        setMessage("Error");
        console.error(error);
      }
    };
    getEstoque();
  }, [setLoading]);

  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return valorFormatado;
    }
  };

  let valorEstoque = 0;
  for (let index = 0; index < estoque.length; index++) {
    if (estoque[index].transactions && estoque[index].transactions.length > 0) {
      const element = estoque[index].transactions[0].produtos[0];

      if (element && typeof element.valorVenda === "number") {
        valorEstoque += element.valorVenda;
      }
    }
  }

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Filtrar o estoque com base no termo de pesquisa
    const filteredResults = estoque.filter((imei) => {
      const lowerCaseTerm = term.toLowerCase();

      const descricao =
        (imei.produtoId &&
          imei.produtoId.descricao.toLowerCase().includes(lowerCaseTerm)) ||
        "";
      const number = imei.number.toLowerCase().includes(lowerCaseTerm);
      return descricao || number;
    });

    setFilteredEstoque(filteredResults);
  };

  const renderTable = () => {
    const estoqueToRender = currentItems;

    // const estoqueToRender = searchTerm ? filteredEstoque : estoque;

    if (loading === false) {
      if (estoqueToRender.length > 0) {
        return (
          <table className="table mb-0 table-striped table-hover mb-3">
            <thead>
              <tr>
                <th>Data</th>
                <th>Transação</th>
                <th>IMEI's</th>
                <th>Preço</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {estoqueToRender.map((imei, index) => {
                const hasTransactions =
                  imei.transactions && imei.transactions.length > 0;

                // Pega a última transação (se houver)
                const lastTransaction = hasTransactions
                  ? imei.transactions[imei.transactions.length - 1]
                  : null;

                let produtoValorVenda = lastTransaction.produtos.find(
                  (produto) => produto.imeiId === imei._id
                );
                let valorVenda = produtoValorVenda
                  ? produtoValorVenda.valorVenda
                  : 0;

                return (
                  <tr key={index}>
                    <td>
                      {lastTransaction &&
                        new Date(
                          lastTransaction.date || lastTransaction.createdAt
                        ).toLocaleDateString("pt-br", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric"
                        })}
                    </td>
                    <td>
                      <strong>
                        {lastTransaction.type.slice(0, 3)}
                        {lastTransaction && lastTransaction.number}
                      </strong>
                    </td>
                    <td>
                      {imei.produtoId && imei.produtoId.descricao} (IMEI:{" "}
                      {imei.number})
                    </td>
                    <td>R$ {formatarValor(valorVenda && valorVenda)}</td>
                    <td>
                      {userData.admin ? (
                        <div
                          className="btn btn-danger"
                          onClick={() => {
                            handleDelete(lastTransaction, imei);
                          }}
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return (
          <div className="text-center text-dark alert alert-warning mt-3">
            Nenhum imei em estoque!
          </div>
        );
      }
    } else {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }
  };

  const handleDelete = async (lastTransaction, imei) => {
    setDeletingCompra(lastTransaction);
    setCurrentIMEI(imei);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      const deletedProduto = await api.deleteProdutoIMEI(currentIMEI._id, {
        deletingCompra,
        currentIMEI
      });
      if (deletedProduto) {
        setLoading(false);
        setDeleteModalOpen(false);
        navigate(0);
      }
    } catch (error) {
      setLoading(false);
      setDeleteModalOpen(false);
      setError(error);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingCompra(null);
  };

  return (
    <div
      className=" d-flex flex-column page"
      style={{
        marginTop: "70px",
        marginBottom: "120px",
       
        padding: "1rem 1rem 1rem 2rem"
      }}
    >
      <div className="d-flex align-items-baseline justify-content-between mb-3">
        <h3>
          <i className="bi bi-phone-vibrate mx-3"></i>Dispositivos em estoque
        </h3>
        <div className="mb-3">
          <div className="d-flex align-items-center alert alert-info">
            <span>
              Quantidade: <b> {estoque.length}</b>
            </span>
            {userData.admin ? (
              <>
                <span className="mx-3"> | </span>
                <span>Total: R$ {formatarValor(valorEstoque)}</span>
              </>
            ) : null}
          </div>

          <Link
            className="btn btn-success"
            to={"/cadastrando/transaction/compras/"}
          >
            Cadastrar IMEIs
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar por imei/descricao"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {filteredEstoque.length > 0 ? (
        <span>{filteredEstoque.length} itens no filtro.</span>
      ) : null}{" "}
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      {error ? <div className="alert alert-danger">{error}</div> : null}
      <div className="border p-2 mb-3 shadow rounded w-100">
        {renderTable()}
      </div>
      {renderPagination()}
      <ModalDeleteImei
        isDeleteModalOpen={isDeleteModalOpen}
        cancelDelete={cancelDelete}
        lastTransaction={deletingCompra}
        currentIMEI={currentIMEI}
        error={error}
        setError={setError}
        userData={userData}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default Dispositivos;
