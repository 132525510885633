import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import ImeiReader from "../components/ImeiReaderVenda";
import SearchProdutoVenda from "../components/SearchProdutoVenda";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const EditTransaction = ({
  setMessage,
  error,
  setError,
  userData,
  setLoading,
  formatarDataEHora
}) => {
  const navigate = useNavigate();

  const { type, id } = useParams();

  const [sellDate, setSellDate] = useState("");

  const [parte, setParte] = useState("");

  const [imeiArray, setImeiArray] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [hasImei, setHasImei] = useState("nao");

  const [valorVenda, setValorVenda] = useState(0);
  const [valorOutros, setValorOutros] = useState(0);

  const [valorTotal, setValorTotal] = useState(0);

  const [erroImei, setErrorImei] = useState(null);

  const [dataPagamento, setDataPagamento] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");

  const [selectedDate] = useState(getCurrentFormattedDate());
  const [caixaDiario, setCaixaDiario] = useState(null);

  const [currentTransaction, setCurrentTransaction] = useState({});
  const [user, setUser] = useState("");

  const [imeisAdicionados, setImeisAdicionados] = useState([]);
  const [imeisRemovidos, setImeisRemovidos] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const getTransaction = await api.getTransactionById(type, id);
        setCurrentTransaction(getTransaction);
        setUser(getTransaction.transactionUser);
        setParte(getTransaction.parteId);

        //FILTRA TODOS OS PRODUTOS E PEGA SOMENTE O QUE TEM IMEI
        const produtosComImei = getTransaction.produtos.filter(
          (produto) => produto.hasImei === true
        );

        //SALVA NA VARIAVEL TODOS OS IMEIID DE DENTRO DE PRODUTOS
        const imeiIds = produtosComImei.map((produto) => produto.imeiId);

        // Percorre cada imeiId
        imeiIds.forEach((imeiId) => {
          // Procura o correspondente em produtosComImei
          const produtoComImei = produtosComImei.find(
            (produto) => produto.imeiId._id === imeiId._id
          );

          // Se encontrado, adiciona valorCompra e valorVenda a imeiId
          if (produtoComImei) {
            imeiId.valorCompra = produtoComImei.valorCompra;
            imeiId.valorVenda = produtoComImei.valorVenda;
          }
        });
        setImeiArray(imeiIds);

        setFormaPagamento(getTransaction.formaPagamento);
        setDataPagamento(getTransaction.dataPagamento);
        setValorTotal(getTransaction.valorTotal);

        // const totalValue = sumImeis();
        handleTotalValueUpdate();
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [type, id]);

  const handleImeiAdd = async (imei) => {
    try {
      const getImei = await api.buscarImeiDadosVenda(imei);
      if (getImei) {
        setErrorImei(null);
        const isImeiAlreadyAdded = imeisAdicionados.some(
          (existingImei) => existingImei.number === getImei.number
        );

        if (!isImeiAlreadyAdded) {
          let valorVenda = 0;
          let valorCompra = 0;
          if (getImei.transactions && getImei.transactions.length > 0) {
            const lastTransaction =
              getImei.transactions[getImei.transactions.length - 1];
            const produto = lastTransaction.produtos.find(
              (produto) => produto.imeiId === getImei._id
            );
            if (produto) {
              valorVenda = parseFloat(produto.valorVenda) || 0;
              valorCompra = parseFloat(produto.valorCompra);
            }
          }
          getImei.valorVenda = valorVenda;
          getImei.valorCompra = valorCompra;

          setImeisAdicionados((prevImeisAdicionados) => {
            const newImeisAdicionados = [...prevImeisAdicionados, getImei];
            return newImeisAdicionados;
          });

          // Atualiza o valor de venda
          setValorVenda((prevValorVenda) => {
            const newValorVenda = prevValorVenda + valorVenda;
            handleTotalValueUpdate(newValorVenda);
            return newValorVenda;
          });
        } else {
          setErrorImei("IMEI já foi incluso na lista");
        }
      } else {
        setErrorImei("Erro ao buscar IMEI");
      }
    } catch (error) {
      setErrorImei(error);
      console.error(error);
    }
  };
  const removeNovosImei = (index) => {
    const updatedImeiArray = [...imeisAdicionados];

    updatedImeiArray.splice(index, 1);
    setImeisAdicionados(updatedImeiArray);
  };
  const removeImei = (index) => {
    const updatedImeiArray = [...imeiArray];

    const removedImei = updatedImeiArray.splice(index, 1)[0];
    setImeiArray(updatedImeiArray);
    setImeisRemovidos((prevImeisRemovidos) => [
      ...prevImeisRemovidos,
      removedImei
    ]);
  };

  const formatarValor = (valor) => {
    const valorFormatado = valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return valorFormatado;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parte !== null) {
      let parteId = parte._id;
      if (formaPagamento !== "") {
        const { _id } = caixaDiario;
        const idCaixa = _id;

        const formData = {
          sellDate,
          parteId,
          imeiArray,
          selectedProducts,
          valorTotal,
          userData,
          dataPagamento,
          formaPagamento,
          imeisRemovidos,
          imeisAdicionados,
          idCaixa
        };

        try {
          await api.editTransaction(type, id, formData);
          // Em seguida, limpo o formulário e fecho o modal.
          setSellDate("");
          setParte(null);
          setImeiArray([]);
          setSelectedProducts([]);
          setValorVenda("");
          setMessage(`${type} editada com sucesso!`);
          navigate("/");
          setTimeout(() => {
            setMessage("");
          }, 4000);
        } catch (error) {
          console.log(error);
          // setError(error);
        }
      } else {
        setError("Forma de pagamento é obrigatória!");
      }
    } else {
      setError("Necessário informar o cliente e ao menos 01 IMEI");
    }
  };

  const sumImeis = () => {
    return [...imeiArray, ...imeisAdicionados]
      .reduce((total, imei) => {
        return total + (imei.transactions[0] ? imei.valorVenda : 0);
      }, 0)
      .toFixed(2);
  };

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad the month and day with leading zeroes if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  //ATUALIZA VALOR DOS OUTROS PRODUTOS
  const handleSellingPriceChange = (updatedProducts, quantity) => {
    // Calcular o valor total com base nos preços de venda atualizados
    const totalValue = calculateTotalValue(updatedProducts);

    // Atualizar os estados
    setValorOutros(totalValue);
  };

  //CALCULA O VALOR DOS OUTROS PRODUTOS PELA QUANTIDADE
  const calculateTotalValue = (products) => {
    return products.reduce((total, product) => {
      return (
        total +
        (product.sellingPrice
          ? parseFloat(product.sellingPrice) * product.quantity
          : 0)
      );
    }, 0);
  };

  const handleTotalValueUpdate = () => {
    const totalValue = parseFloat(valorVenda) + parseFloat(valorOutros);
    setValorTotal(totalValue);
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorImei(null);
    }, 3000);
  });

  useEffect(() => {
    const getCaixa = async () => {
      try {
        setLoading(true);

        if (selectedDate) {
          const getCaixaDia = await api.checkCaixaAberto(selectedDate);
          setCaixaDiario(getCaixaDia);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (userData) {
      getCaixa();
    }
    setLoading(false);
  }, [selectedDate, userData]);

  useEffect(() => {
    const totalValue = sumImeis();
    setValorVenda(parseFloat(totalValue));
    handleTotalValueUpdate(); // Ensure that this call is not happening before setValorVenda completes
  }, [imeiArray, selectedProducts]);

  useEffect(() => {
    handleTotalValueUpdate();
  }, [valorOutros, valorVenda]);

  return (
    <div
      className="d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <h5 className="mt-3">
        <i className="bi bi-pencil-square"></i> Editando {type.slice(0, -1)}{" "}
      </h5>
      <small>
        <strong>
          {type.slice(0, 3).toUpperCase()}
          {currentTransaction.number}
        </strong>{" "}
        de {formatarDataEHora(currentTransaction.createdAt)}h, cadastrada por{" "}
        {user.fullName}
      </small>
      <div className="d-flex">
        <div className="d-flex flex-column w-100 p-3">
          <div className="d-flex flex-column ">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="form-group col-12 col-lg-9">
                <label>Cliente: </label>
                <div className="d-flex align-items-center btn btn-primary">
                  <span className="mx-3">
                    {parte.fullName} ({parte.document})
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column col-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="buyDate">Data da venda</label>
                  <input
                    type="date"
                    className="form-control"
                    id="buyDate"
                    name="buyDate"
                    value={sellDate}
                    onChange={(e) => setSellDate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-between">
              <div className="w-100">
                <ImeiReader onImeiAdd={handleImeiAdd} />
                {erroImei && (
                  <div className="alert alert-danger">{erroImei}</div>
                )}
                {imeisAdicionados.length > 0 && (
                  <div className="bg-light mb-3 border rounded">
                    <label
                      style={{ backgroundColor: "#ade6b6" }}
                      className="w-100 text-center rounded"
                    >
                      Imeis que serão adicionados a venda:
                    </label>
                    <table className="table table-striped">
                      <tbody>
                        {imeisAdicionados &&
                          imeisAdicionados.map((imei, index) => {
                            return (
                              <tr key={index} className="lista-imeis w-100">
                                <td
                                  style={{ width: "5%" }}
                                  className="p-0 px-1"
                                >
                                  <div
                                    className="btn btn-danger"
                                    style={{
                                      width: "auto",
                                      fontSize: "0.8rem"
                                    }}
                                    onClick={() => removeNovosImei(index)}
                                  >
                                    X
                                  </div>
                                </td>
                                <td className="p-0 px-1">
                                  {imei.number && imei.number}
                                </td>
                                <td className="p-0 px-1">
                                  {imei && imei.produtoId.descricao}
                                </td>

                                <td
                                  style={{ width: "20%" }}
                                  className="p-0 px-1 text-center bg-light"
                                >
                                  <div className="input-group">
                                    <span className="input-group-text">R$</span>

                                    <NumericFormat
                                      className="form-control"
                                      value={imei.valorVenda}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      allowNegative={false}
                                      onValueChange={(values) => {
                                        const updatedImeiArray = [...imeiArray];
                                        updatedImeiArray[index].valorVenda =
                                          parseFloat(values.value) || 0;
                                        setImeiArray(updatedImeiArray);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}{" "}
                {imeiArray.length > 0 && (
                  <div className="bg-light mb-3 border rounded">
                    <label
                      style={{ backgroundColor: "#adcfe6" }}
                      className="w-100 text-center rounded"
                    >
                      Imeis da venda:
                    </label>
                    <table className="table table-striped">
                      <tbody>
                        {imeiArray &&
                          imeiArray.map((imei, index) => {
                            return (
                              <tr key={index} className="lista-imeis w-100">
                                <td
                                  style={{ width: "5%" }}
                                  className="p-0 px-1"
                                >
                                  <div
                                    className="btn btn-danger"
                                    style={{
                                      width: "auto",
                                      fontSize: "0.8rem"
                                    }}
                                    onClick={() => removeImei(index)}
                                  >
                                    X
                                  </div>
                                </td>
                                <td className="p-0 px-1">
                                  {imei.number && imei.number}
                                </td>
                                <td className="p-0 px-1">
                                  {imei && imei.produtoId.descricao}
                                </td>

                                <td
                                  style={{ width: "20%" }}
                                  className="p-0 px-1 text-center bg-light"
                                >
                                  <div className="input-group">
                                    <span className="input-group-text">R$</span>

                                    <NumericFormat
                                      className="form-control"
                                      value={imei.valorVenda}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      allowNegative={false}
                                      onValueChange={(values) => {
                                        const updatedImeiArray = [...imeiArray];
                                        updatedImeiArray[index].valorVenda =
                                          parseFloat(values.value) || 0;
                                        setImeiArray(updatedImeiArray);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
                {imeisRemovidos.length > 0 && (
                  <div className="mb-3 border rounded">
                    <label
                      style={{ backgroundColor: "#e7939b" }}
                      className="w-100 text-center rounded"
                    >
                      Imeis que serão removidos da venda:
                    </label>
                    <table
                      style={{ fontSize: "0.8rem" }}
                      className="table table-danger"
                    >
                      <tbody>
                        {imeisRemovidos &&
                          imeisRemovidos.map((imei, index) => {
                            return (
                              <tr key={index} className="lista-imeis w-100">
                                <td>{imei.number && imei.number}</td>
                                <td>{imei && imei.produtoId.descricao}</td>
                                <td className="text-center">
                                  R$ {formatarValor(imei.valorVenda)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <small
              className="p-1 rounded px-3"
              style={{
                backgroundColor: "#fff3cd"
              }}
            >
              <small
                style={{
                  backgroundColor: "#fff3cd",
                  color: "#664d03",
                  borderColor: "#ffe69c"
                }}
              >
                *Serão mantidos na venda o(s) Imei(s) do quadro de Imeis que
                serão adicionados e o(s) Imei(s) do quadro de Imei(s) da venda.
              </small>
            </small>
            <hr />
            <div className="w-100 d-flex justify-content-between">
              <div className="form-group">
                <label htmlFor="hasImei">
                  Deseja incluir algum produto que não tem IMEI/Serial?
                </label>
                <div>
                  <input
                    type="radio"
                    id="hasImei"
                    name="hasImei"
                    value="sim"
                    checked={hasImei === "sim"}
                    onChange={(e) => setHasImei(e.target.value)}
                  ></input>
                  <label htmlFor="sim" className="mx-3">
                    Sim
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="hasImei"
                    name="hasImei"
                    value="nao"
                    checked={hasImei === "nao"}
                    onChange={(e) => setHasImei(e.target.value)}
                  ></input>
                  <label htmlFor="nao" className="mx-3">
                    Não
                  </label>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              {hasImei === "sim" ? (
                <>
                  <SearchProdutoVenda
                    setError={setError}
                    error={error}
                    onSellingPriceChange={handleSellingPriceChange}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    formatarValor={formatarValor}
                  />
                </>
              ) : null}
            </div>
            <div className="w-100 d-flex justify-content-between">
              <div className="form-group col-md-2">
                <label htmlFor="dataPagamento">Data de Pagamento</label>
                <input
                  type="date"
                  className="form-control"
                  id="dataPagamento"
                  name="dataPagamento"
                  value={dataPagamento}
                  onChange={(e) => setDataPagamento(e.target.value)}
                />
              </div>

              <div className="form-group col-md-8">
                <label htmlFor="formaPagamento">Forma de Pagamento</label>
                <select
                  className="form-control"
                  id="formaPagamento"
                  name="formaPagamento"
                  value={formaPagamento}
                  onChange={(e) => setFormaPagamento(e.target.value)}
                >
                  <option value="">Selecione a forma de pagamento</option>
                  <option value="cartao_credito">Cartão de Crédito</option>
                  <option value="cartao_debito">Cartão de Débito</option>
                  <option value="dinheiro">Dinheiro</option>
                  <option value="pix">PIX</option>
                  <option value="transferencia_bancaria">
                    Transferência Bancária
                  </option>
                  <option value="saldo_carteira">Saldo da carteira</option>
                </select>
              </div>
            </div>
            <div className="text-center valorVenda rounded mb-3 p-3">
              <div>Valor total da venda</div>
              <div>R$ {valorTotal && formatarValor(valorTotal)}</div>
            </div>

            {error ? (
              <div className="alert alert-danger text-center">
                <b>{error}</b>
              </div>
            ) : null}
            {formaPagamento === "saldo_carteira" &&
              parte &&
              parte.carteira < valorTotal && (
                <div className="alert alert-danger text-center">
                  <b>
                    A parte não possui saldo disponível para realizar essa
                    transação!
                  </b>
                </div>
              )}
            <div className="d-flex flex-column align-items-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Salvar alteração
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTransaction;
