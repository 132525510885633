import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { ViewImei } from "../components/ViewImei";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Relatorio = ({
  message,
  error,
  loading,
  setLoading,
  formatarDataEHora,
  formatarValor
}) => {
  const [imeis, setImeis] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEstoque, setFilteredEstoque] = useState([]);

  const [showModalView, setShowModalView] = useState(false);
  const [currentImei, setCurrentImei] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  // const navigate = useNavigate();

  useEffect(() => {
    const getImeis = async () => {
      try {
        setLoading(true);
        const estoque = await api.getRelatorio();
        setImeis(estoque);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getImeis();
  }, [setLoading]);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Filtrar o estoque com base no termo de pesquisa
    const filteredResults = imeis.filter((imei) =>
      imei.number.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredEstoque(filteredResults);
  };

  const viewImeiData = (imei) => {
    setCurrentImei(imei);
    setShowModalView(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (searchTerm ? filteredEstoque : imeis).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const totalItems = searchTerm ? filteredEstoque.length : imeis.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxPagesToShow = 5;

    const createPageArray = (from, to) => {
      const result = [];
      for (let i = from; i <= to; i++) {
        result.push(i);
      }
      return result;
    };

    if (totalPages <= maxPagesToShow) {
      pageNumbers.push(...createPageArray(1, totalPages));
    } else {
      const leftPages = Math.max(
        1,
        currentPage - Math.floor(maxPagesToShow / 2)
      );
      const rightPages = Math.min(
        totalPages,
        currentPage + Math.floor(maxPagesToShow / 2)
      );

      if (leftPages > 1) pageNumbers.push(1);
      if (leftPages > 2) pageNumbers.push("...");
      pageNumbers.push(...createPageArray(leftPages, rightPages));
      if (rightPages < totalPages - 1) pageNumbers.push("...");
      if (rightPages < totalPages) pageNumbers.push(totalPages);
    }

    return (
      <nav>
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="page-item clickable">
              <Link
                to={""}
                onClick={() => paginate(currentPage - 1)}
                className="page-link"
              >
                &laquo;
              </Link>
            </li>
          )}
          {pageNumbers.map((number, index) =>
            number === "..." ? (
              <li key={index} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li
                key={index}
                className={`page-item clickable ${
                  number === currentPage ? "active" : ""
                }`}
              >
                <Link
                  to={""}
                  onClick={() => paginate(number)}
                  className="page-link"
                >
                  {number}
                </Link>
              </li>
            )
          )}
          {currentPage < totalPages && (
            <li className="page-item clickable">
              <Link
                to={""}
                onClick={() => paginate(currentPage + 1)}
                className="page-link"
              >
                &raquo;
              </Link>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const renderTable = () => {
    // const imeisToRender = searchTerm ? filteredEstoque : imeis;
    const imeisToRender = currentItems;
    if (loading === false) {
      if (imeisToRender.length > 0) {
        return (
          <table className="table mb-0 table-striped table-hover">
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Produto</th>
                <th style={{ width: "40%" }}>IMEI's</th>
                <th style={{ width: "15%" }}>Status</th>
                <th style={{ width: "5%" }}>Ver</th>
              </tr>
            </thead>
            <tbody>
              {imeisToRender.map((imei, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {imei.produtoId && imei.produtoId.marca}
                      {" - "}
                      {imei.produtoId && imei.produtoId.descricao}
                    </td>
                    <td>IMEI: {imei.number}</td>
                    <td>
                      <small>
                        <div
                          className={`d-flex flex-column border p-2 m-0 ${
                            imei.status === "DISPONIVEL"
                              ? "alert alert-success"
                              : imei.status === "VENDIDO"
                              ? "alert alert-danger"
                              : "alert alert-warning"
                          }`}
                          style={{ borderRadius: "5px", textAlign: "center" }}
                        >
                          <b>{imei.status}</b>
                        </div>
                      </small>
                    </td>
                    <td>
                      <div
                        className="btn btn-info"
                        onClick={() => {
                          viewImeiData(imei);
                        }}
                      >
                        <i className="bi bi-eye-fill"></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return (
          <div className="text-center text-dark alert alert-warning mt-3">
            Nenhum produto em estoque!
          </div>
        );
      }
    } else {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-phone-vibrate mx-3"></i>História do IMEI
        </h3>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar produto"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {filteredEstoque.length > 0 ? (
        <span>{filteredEstoque.length} itens no filtro.</span>
      ) : null}{" "}
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      {error ? <div className="alert alert-danger">{error}</div> : null}
      <div className="d-flex align-items-start">
        <div className="border p-2 px-3  rounded w-100 d-flex align-items-start">
          {renderTable()}
          <ViewImei
            setShowModalView={setShowModalView}
            showModalView={showModalView}
            currentImei={currentImei}
            formatarDataEHora={formatarDataEHora}
            formatarValor={formatarValor}
          />
        </div>
      </div>
      {renderPagination()}
    </div>
  );
};

export default Relatorio;
