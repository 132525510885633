import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AppNavbar = () => {
  const { pathname } = useLocation();

  const { userData } = useAuth() || {};

  const { name } = userData.userData || "";

  const isActive = (path) => pathname === path;

  return (
    <>
      <Navbar
        className="px-3 d-flex align-items-center justify-content-between"
        expand="lg"
      >
        <Nav className="mr-auto px-3 nav-atalho">
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/compras/"
            active={isActive("/cadastrando/transaction/compras/")}
          >
            Cadastrar Compras
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/vendas/"
            active={isActive("/cadastrando/transaction/vendas/")}
          >
            Cadastrar Vendas
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/devolucao/"
            active={isActive("/cadastrando/transaction/devolucao/")}
          >
            Devolução
          </Nav.Link>
        </Nav>
        <div
          className="d-flex align-items-center justify-content-end "
          style={{ fontWeight: "bold", marginRight: "10px" }}
        >
          {name}
          <i className="bi bi-person-circle mx-1 fs-2"></i>
        </div>
        {/* </Navbar.Collapse> */}
      </Navbar>
    </>
  );
};

export default AppNavbar;
