import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../utils/api.utils";
import { ViewTransaction } from "../components/ViewTransaction";
import { CircularProgress } from "@mui/material";

const Transactions = ({
  loading,
  setLoading,
  formatarDataEHora,
  formatarValor,
  setError,
  error
}) => {
  const { type } = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [currentTransaction, setCurrentTransaction] = useState("");
  const [showModalView, setShowModalView] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Defina o número de itens por página aqui

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    const filteredResults = transactions.filter((transaction) => {
      return (
        transaction.number.toString().includes(term) ||
        (transaction.parteId &&
          transaction.parteId.fullName
            .toLowerCase()
            .includes(term.toLowerCase()))
      );
    });

    setFilteredTransactions(filteredResults);
  };

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoading(true);
        const data = await api.getAllTransactions();
        setTransactions(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getTransactions();
  }, []);

  const viewCaixa = (transaction) => {
    setWidth("w-50");
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setCurrentTransaction(transaction);
    setShowModalView(true);
  };

  const cancelView = () => {
    setWidth("w-100");
    setShowModalView(false);
    setCurrentTransaction(null);
  };

  const [width, setWidth] = useState("w-100");

  useEffect(() => {
    setWidth("w-100");
    setShowModalView(false);
  }, [type]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderTransactions = () => {
    const activeTransactions = (
      searchTerm ? filteredTransactions : transactions
    ).filter(
      (transaction) =>
        // (transaction.status === "ATIVO" ||
        //   transactions.status === "DEVOLVIDO") &&
        transaction.type === type.toUpperCase()
    );

    activeTransactions.sort((a, b) => b.number - a.number);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const current = activeTransactions.slice(indexOfFirstItem, indexOfLastItem);

    if (current.length > 0) {
      return (
        <table className="table mb-0 table-striped table-hover">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th style={{ width: "20%" }}>Data</th>
              <th>Valor total</th>
              <th>
                {type !== "compras" ? (
                  <span>Cliente</span>
                ) : (
                  <span>Fornecedor(a)</span>
                )}
              </th>
              <th>
                {type !== "compras" ? (
                  <span>Vendedor(a)</span>
                ) : (
                  <span>Comprador(a)</span>
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {current.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">
                    <strong>
                      {transaction.type.slice(0, 3) + transaction.number}
                    </strong>
                  </td>
                  <td>{formatarDataEHora(transaction.createdAt)}h</td>
                  <td>R$ {formatarValor(transaction.valorTotal)}</td>
                  <td>{transaction.parteId && transaction.parteId.fullName}</td>
                  <td>{transaction.transactionUser.fullName}</td>
                  <td className="text-center" style={{ width: "5%" }}>
                    {/* {transaction.status === "ATIVO" ? ( */}
                    <div
                      className="btn btn-success"
                      onClick={() => {
                        viewCaixa(transaction);
                      }}
                    >
                      <i className="bi bi-eye-fill"></i>
                    </div>
                    {/* ) : null} */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center alert alert-info">
          Nenhum transaction aberto!
        </div>
      );
    }
  };

  const renderPagination = () => {
    const activeTransactions = (
      searchTerm ? filteredTransactions : transactions
    ).filter(
      (transaction) =>
        transaction.status === "ATIVO" &&
        transaction.type === type.toUpperCase()
    );
    const totalPages = Math.ceil(activeTransactions.length / itemsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    // Página atual e total de páginas
    const current = currentPage;
    const maxPages = 5;
    let startPage, endPage;

    if (totalPages <= maxPages) {
      // Total de páginas menor que o máximo de páginas que queremos mostrar
      startPage = 1;
      endPage = totalPages;
    } else {
      // Total de páginas maior que o máximo de páginas que queremos mostrar
      if (current <= Math.ceil(maxPages / 2)) {
        startPage = 1;
        endPage = maxPages;
      } else if (current + Math.floor(maxPages / 2) >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = current - Math.floor(maxPages / 2);
        endPage = current + Math.floor(maxPages / 2);
      }
    }

    const paginationRange = (start, end) => {
      return Array.from({ length: end - start + 1 }, (v, k) => k + start);
    };

    const pagesToShow = paginationRange(startPage, endPage);

    return (
      <nav className="d-flex justify-content-center">
        <ul className="pagination d-flex flex-wrap justify-content-center">
          {current > 1 && (
            <li className="page-item">
              <button
                onClick={() => paginate(current - 1)}
                className="page-link"
              >
                &laquo;
              </button>
            </li>
          )}
          {startPage > 1 && (
            <>
              <li className="page-item">
                <button onClick={() => paginate(1)} className="page-link">
                  1
                </button>
              </li>
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            </>
          )}
          {pagesToShow.map((number) => (
            <li
              key={number}
              className={`page-item ${current === number ? "active" : ""}`}
            >
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
          {endPage < totalPages && (
            <>
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
              <li className="page-item">
                <button
                  onClick={() => paginate(totalPages)}
                  className="page-link"
                >
                  {totalPages}
                </button>
              </li>
            </>
          )}
          {current < totalPages && (
            <li className="page-item">
              <button
                onClick={() => paginate(current + 1)}
                className="page-link"
              >
                &raquo;
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-cash-coin"></i>{" "}
          {type !== "devolucao" ? (
            type[0].toUpperCase() + type.slice(1)
          ) : (
            <>Devolução</>
          )}
        </h3>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar por número da compra/venda/devolução ou nome do fornecedor/cliente"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {!loading ? (
        <div className="d-flex align-items-start">
          <div
            className={`border p-2 shadow rounded ${width} d-flex flex-column`}
          >
            {renderTransactions()}
          </div>
          <ViewTransaction
            setShowModalView={setShowModalView}
            showModalView={showModalView}
            currentTransaction={currentTransaction}
            formatarValor={formatarValor}
            formatarDataEHora={formatarDataEHora}
            cancelView={cancelView}
            setLoading={setLoading}
            setError={setError}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      )}
      {renderPagination()}
    </div>
  );
};

export default Transactions;
