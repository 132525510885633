export const formatarData = (dataParaFormatar) => {
  return new Date(dataParaFormatar).toLocaleDateString("pt-br", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
};
export const formatarDataEHora = (dataParaFormatar) => {
  return new Date(dataParaFormatar).toLocaleDateString("pt-br", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Sao_Paulo",
    hour12: false
  });
};
export const formatarValor = (valor) => {
  if (valor) {
    const valorFormatado = valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return valorFormatado;
  }
};
